import request from '@/request';
const part_url = "/oa/salary/";

export default{
    saveNotice:(content)=>{
        let url = part_url+"addOrUpdateNotice";
        return request.post(url,{}, content);
    },
    getNoticeList:(pageNow,pageSize,params)=>{
        let url = part_url + "getNoticeList";
        return request.get(url,{pageNow,pageSize,...params}, {});
    },
    getNoticeById(id){
        let url = part_url + "getNoticeById";
        return request.get(url,{id}, {});
    },
    removeNotice(id){
        let url = part_url + "removeNoticeById";
        return request.get(url,{id}, {});
    },
    importExcel(){
        return part_url+"importExcel"
    },
    importExcel1(params){
        let url = part_url+"importExcel";
        return request.upload(url,params)
    },
    exportData(params,onProgress,onError){
        return request.downloadFile(part_url+'exportExcel',params,'工资表.xls',onProgress,onError);
    },
    getSalaryList(params){
        let url = part_url + "getSalaryList";
        return request.get(url,params, {});
    },
    deleteBatch(params){
        let url = part_url + "deleteBatch";
        return request.post(url,params,{});
    }
}
