<template>
    <a-spin :tip="loadingTip" :spinning="isloading">
      <div>
        <div v-html="form.content"></div>
      </div>
    </a-spin>
  </template>
  
  <script>
  import salarynoticeApi from "@/api/personal/salary"
  export default {
    name: "salarynotice",
    props: {
      infos: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        content: "",
        isloading: false,
        loadingTip: "加载中...",
        labelCol: { span: 4 },
        wrapperCol: { span: 17 },
        form: {
        },
      };
    },
    components: {
    },
    mounted() {

      if (this.infos && this.infos.id != 0) {
        this.getInfo();
      } 
    },
    methods: {
      
      htmlDecode(text) {
        if (text) {
          if (text.indexOf("lt", "gt", "amp", "quot") > -1) {
            var temp = document.createElement("div");
            temp.innerHTML = text;
            var output = temp.innerHTML;
            temp = null;
            // 去掉标签中的"\"
            var str = output.replace(/\\/g, "");
            // 标点替换
            str = str.replace(/&ldquo;/g, "“");
            str = str.replace(/&amp;ldquo;/g, "“");
            str = str.replace(/&rdquo;/g, "”");
            str = str.replace(/&amp;rdquo;/g, "”");
            str = str.replace(/&laquo;/g, "«");
            str = str.replace(/&raquo;/g, "»");
            str = str.replace(/&lsquo;/g, "‘");
            str = str.replace(/&rsquo/g, "’");
            str = str.replace(/&lt;/g, "<");
            str = str.replace(/&gt;/g, ">");
            str = str.replace(/&hellip;/g, "”");
            str = str.replace(/&rdquo;/g, "…");
            return str;
          } else {
            return text;
          }
        } else {
          return text;
        }
      },
      getInfo() {
        this.isloading = true;
        salarynoticeApi
          .getNoticeById(this.infos.id)
          .then((res) => {
            if (res.errorCode == this.$commons.RespCode.success) {
              if (res.data.content) {
                this.content = this.htmlDecode(res.data.content);
              }
              delete res.data.content;
              this.form = {
                ...res.data,
                content: this.content
              };
              this.isloading = false;
            } else {
              this.$message.error(res.errorMsg);
            }
          })
          .catch(() => {
            this.isloading = false;
          });
      },
      
     
      
      cancelClick() {
        this.$emit("callback");
      },
    },
  };
  </script>
  <style>
  .ant-calendar-footer-extra span {
    margin: 0 9px;
    cursor: pointer;
  }
  #content div {
    line-height: 1.5;
  }
  </style>