<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="articleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入" v-model="form.title" :maxLength="100" />
        </a-form-model-item>

        <a-form-model-item label="内容" prop="content">
          <vue-ueditor-wrap
            editor-id="content"
            :config="myConfig"
            v-model="content"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <a-button type="primary" @click="handleSubmit()">保存</a-button>

          <a-button @click="cancelClick" style="margin-left: 10px"
            >取消</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>
  
  <script>
import VueUeditorWrap from "vue-ueditor-wrap";
import request from "@/request";
import salarynoticeApi from "@/api/personal/salary";
export default {
  name: "salarynotice",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
    noticetype: {
      type: String,
    },
  },
  data() {
    return {
      content: "",
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      form: {},
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
      },
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 300,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: "",
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$config.editorConfig.toolbars,
      },
    };
  },
  components: {
    VueUeditorWrap,
  },
  mounted() {
    this.uploadFileUrl = request.getUploadUrl("cms");
    this.myConfig.serverUrl = request.getUploadUrl("cms");
    if (this.infos && this.infos.id != 0) {
      this.getInfo();
    }
  },
  methods: {
    htmlDecode(text) {
      if (text) {
        if (text.indexOf("lt", "gt", "amp", "quot") > -1) {
          var temp = document.createElement("div");
          temp.innerHTML = text;
          var output = temp.innerHTML;
          temp = null;
          // 去掉标签中的"\"
          var str = output.replace(/\\/g, "");
          // 标点替换
          str = str.replace(/&ldquo;/g, "“");
          str = str.replace(/&amp;ldquo;/g, "“");
          str = str.replace(/&rdquo;/g, "”");
          str = str.replace(/&amp;rdquo;/g, "”");
          str = str.replace(/&laquo;/g, "«");
          str = str.replace(/&raquo;/g, "»");
          str = str.replace(/&lsquo;/g, "‘");
          str = str.replace(/&rsquo/g, "’");
          str = str.replace(/&lt;/g, "<");
          str = str.replace(/&gt;/g, ">");
          str = str.replace(/&hellip;/g, "”");
          str = str.replace(/&rdquo;/g, "…");
          return str;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    /**获取详情 */
    getInfo() {
      this.isloading = true;
      salarynoticeApi
        .getNoticeById(this.infos.id)
        .then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data.content) {
              this.content = this.htmlDecode(res.data.content);
            }
            delete res.data.content;
            this.form = {
              ...res.data,
              content: this.content,
            };
            this.isloading = false;
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },

    handleSubmit() {
      this.$refs.articleForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.loadingTip = "保存中...";
          let formData = {
            ...this.form,
            content: this.content,
            type: this.noticetype,
          };

          if (this.infos && this.infos.id != 0) {
            formData.id = this.infos.id;
          }
          salarynoticeApi
            .saveNotice(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback");
              } else {
                this.$message.error(res.errorMsg);
              }
              this.isloading = false;
            })
            .catch(() => {
              this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },

    /**取消 */
    cancelClick() {
      this.$emit("callback");
    },
  },
};
</script>
  <style>
.ant-calendar-footer-extra span {
  margin: 0 9px;
  cursor: pointer;
}
#content div {
  line-height: 1.5;
}
</style>