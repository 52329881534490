<template>
    <div>
        <div class="tableList">
          <div>
            <a-table
              rowKey="id"
              size="small"
              bordered
              :columns="list.columns"
              :pagination="list.pagination"
              @change="onPageChange"
              :dataSource="list.datas"
              :loading="isloading"
              :locale="{ emptyText: '暂无数据' }"
            >
              <span slot="action" slot-scope="action">
                <p-button v-if="noticetype=='documentsearch'"
                  name="编辑"
                  @click="showInfo(action.id)"
                  size="small"
                  type="primary"
                          :roles="['文献查询-发布公告']"
                />
                <p-button v-if="noticetype=='salary'"
                          name="编辑"
                          @click="showInfo(action.id)"
                          size="small"
                          type="primary"
                          :roles="['工资-发布公告']"
                />
                <a-popconfirm @confirm="delInfo(action.id)">
                  <div slot="title">是否确认删除此文章？</div>
                  <a-icon slot="icon" type="question-circle-o" />
                  <p-button v-if="noticetype=='documentsearch'"
                    name="删除"
                    size="small"
                    type="danger"
                            :roles="['文献查询-发布公告']"
                  />
                  <p-button v-if="noticetype=='salary'"
                            name="删除"
                            size="small"
                            type="danger"
                            :roles="['工资-发布公告']"
                  />
                </a-popconfirm>
                <a-button type="default" size="small" @click="showdetail(action)">查看</a-button>
              </span>
            </a-table>
          </div>
      </div>
      <a-modal
        title="编辑"
        v-model="addedit.visible"
        width="60%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <salarynotice @callback="save" :infos="addedit.info" />
      </a-modal>
      <a-modal
        :title="showmodal.info.title"
        v-model="showmodal.visible"
        width="60%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <salarynoticeshow @callback="closeshow" :infos="showmodal.info" />
      </a-modal>
    </div>
  </template>
  <script>
  import salarynotice from "./salarynotice.vue";
  import salarynoticeshow from "./salarynoticeshow.vue";
  import salarynoticeApi from "@/api/personal/salary";
  const columns = [
    {
      dataIndex: "title",
      align: "left",
      title: "标题",
      ellipsis: true,
    },

    {
      dataIndex: "time",
      align: "time",
      title: "添加时间",
    },

    {
      title: "操作",
      key: "action",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  let _this;
  export default {
    name: "salarynoticelist",
    data() {
      return {
        list: {
          columns, //表头
          datas: [], //数据
          pagination: {
            showQuickJumper: true,
            showSizeChanger: false,
            total: 0,
            current: 1,
            pageSize: 10,
            showTotal: (total) => `共 ${total} 条`,
          },
        },
        addedit: {
          info: {},
          visible: false,
        },
        showmodal: {
          info: {},
          visible: false,
        },
        search: {
          title: "",
        },
        isloading: false,
      };
    },
    components: {
      salarynotice,salarynoticeshow
    },
    props:{
      noticetype:{
        type:String
      }
    },
    mounted() {
      _this = this;
      _this.GetList();
    },
    methods: {
      showInfo(id) {
        //打开弹出框
        this.addedit.visible = true;
        this.addedit.info = {id};
      },
      showdetail(info){
        this.showmodal.visible=true;
        this.showmodal.info=info;
      },
      closeshow(){
        this.showmodal.visible=false;
      },
      GetList() {
        //获取列表
        _this.isloading = true;
        let queryData = { ..._this.search,type:this.noticetype };
        salarynoticeApi
          .getNoticeList(
            _this.list.pagination.current,
            _this.list.pagination.pageSize,
            queryData
          )
          .then((res) => {
            if (res.errorCode == _this.$commons.RespCode.success) {
              _this.list.datas = res.data.records;
              _this.list.pagination.total = Number(res.data.total);
            } else {
              _this.$message.error(res.errorMsg);
            }
            _this.isloading = false;
          })
          .catch(() => {
            _this.isloading = false;
          });
      },
      onPageChange(page) {
        _this.list.pagination.current = page.current;
        _this.list.pagination.pageSize = page.pageSize;
        this.GetList();
      },

      /**删除 */
      delInfo(id) {
        _this.isloading = true;
        salarynoticeApi
          .removeNotice(id)
          .then((res) => {
            if (res.errorCode == _this.$commons.RespCode.success) {
              _this.$message.success(res.errorMsg);
              _this.GetList();
            } else {
              _this.$message.error(res.errorMsg);
              _this.isloading = false;
            }
          })
          .catch(() => {
            _this.isloading = false;
          });
      },

      save() {
        this.addedit.visible = false;
        this.GetList();
      },
    },
  };
  </script>

  <style>
  .left {
    float: left;
    width: 12%;
    height: calc(100vh - 120px);
    padding: 10px;
  }
  .right {
    float: left;
    width: 88%;
  }
  </style>

